import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddEditDonations({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
}) {
  // "trans_date":"2024-10-10",
  //   "payment_type":"1",
  //   "from_code":"E001",
  //   "to_code":"V001",
  //   "amount":"1000"

  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [fromCode, setFromCode] = useState("");
  const [toCode, setToCode] = useState("");
  const [article, setArticle] = useState("");
  const [amountPerQuantity, setAmountPerQuantity] = useState("");
  const [via, setVia] = useState("");
  const [type, setType] = useState();
  const [total, setTotal] = useState("");
  const [quantity, setQuantity] = useState("");

  const typeOptions = [
    { value: 1, label: "Quantity" },
    { value: 2, label: "Total Amount" },
  ];

  const handleClose = () => {
    setDate("");

    setRemarks("");
    setFromCode("");
    setToCode("");
    setArticle("");
    setAmountPerQuantity("");
    setVia("");
    setType("");
    setTotal("");
    setQuantity("");
    handleCloseAddCred();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setDate(showAddCred?.date);

      setRemarks(showAddCred?.remarks);
      setFromCode(showAddCred?.from_code);
      setToCode(showAddCred?.to_code);
      setArticle(showAddCred?.article);
      setAmountPerQuantity(showAddCred?.amount_per_quantity);
      setVia(showAddCred?.via);
      let foundType = typeOptions.find(
        (option) => option.value == showAddCred?.type
      );
      setType(foundType);
      setTotal(showAddCred?.amount);
      setQuantity(showAddCred?.quantity || "");
    }
  }, [showAddCred]);

  useEffect(() => {
    if (type?.value == 1) {
      const calculatedTotal =
        parseFloat(quantity) * parseFloat(amountPerQuantity);
      setTotal(calculatedTotal);
    }
  }, [quantity, amountPerQuantity]);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              showAddCred?.id || null,
              date,
              fromCode,
              toCode,
              article,
              amountPerQuantity,
              via,
              type?.value,
              total,
              remarks,
              quantity,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Date
            </label>
            <input
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="input-box"
              placeholder="Select Date"
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              From Code
            </label>
            <input
              required
              value={fromCode}
              onChange={(e) => setFromCode(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter From Code"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              To Code
            </label>
            <input
              required
              value={toCode}
              onChange={(e) => setToCode(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter To Code"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Article
            </label>
            <input
              required
              value={article}
              onChange={(e) => setArticle(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Article"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Type
            </label>
            <Select
              value={type}
              required
              onChange={(option) => setType(option)}
              options={typeOptions}
              placeholder="Select Type"
              isSearchable
              isClearable
            />
          </div>

          {type?.value == 1 && (
            <>
              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Quantity
                </label>
                <input
                  required
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  type="number"
                  className="input-box"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Amount / Quantity
                </label>
                <input
                  required
                  value={amountPerQuantity}
                  onChange={(e) => setAmountPerQuantity(e.target.value)}
                  type="number"
                  className="input-box"
                  placeholder="Enter Amount / Quantity"
                />
              </div>
            </>
          )}

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Total Amount
            </label>
            <input
              required
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter Total"
              disabled={type?.value == 1}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Via
            </label>
            <input
              required
              value={via}
              onChange={(e) => setVia(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Via"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Remarks
            </label>
            {/* textarea */}
            <textarea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className="input-box"
              placeholder="Enter Remarks"
            ></textarea>
          </div>

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditDonations;
