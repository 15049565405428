import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";

import React from "react";
import Loader from "./Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AddPaymentPurposeModal({
  showAddItem,
  handleCloseAddItem,
  handleAddEditItems,
  isUpdatingItem,
}) {
  const navigate = useNavigate();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [paymentPorpose, setPaymentPorpose] = useState();
  const [userList, setUserList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [showAddPurposeModal, setShowAddPurposeModal] = useState(false);
  const [newPurposeName, setNewPurposeName] = useState("");

  const handleClose = () => {
    setFrom();
    setTo();
    setPaymentPorpose();
    handleCloseAddItem();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data || [];
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  async function getLedgerList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/payment_purpose`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data || [];
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  async function saveNewPurpose() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/addpaymentpurpose_ledger`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          name: newPurposeName,
        },
      });

      if (response.status === 200) {
        const ledgerlist = await getLedgerList();
        setLedgerList(ledgerlist);
        const newPurpose = ledgerlist.find(
          (item) => item.id === response.data?.data?.id
        );
        setPaymentPorpose({
          value: newPurpose.id,
          label: newPurpose.name,
        });
        setShowAddPurposeModal(false);
        setNewPurposeName("");
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  }

  useEffect(() => {
    (async () => {
      const users = await getContractors(
        "Bank,Company,Director,Employee,Firm,Head Office,Vendor,Contractor"
      );
      setUserList(users);
      const ledgerlist = await getLedgerList();
      setLedgerList(ledgerlist);
      if (showAddItem?.id) {
        let from = users.find((item) => item.code == showAddItem?.from);
        let to = users.find((item) => item.code == showAddItem?.to);
        setFrom(
          showAddItem?.from
            ? {
                value: from?.code,
                label: from?.code,
              }
            : null
        );
        setTo(
          showAddItem?.to
            ? {
                value: to?.code,
                label: to?.code,
              }
            : null
        );
        setPaymentPorpose(showAddItem?.porpose);
      }
    })();
  }, [showAddItem]);

  return (
    <>
      <Modal show={showAddItem} onHide={handleClose} animation={true} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {showAddItem?.id ? "Edit" : "Add"} Purpose of Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) =>
              handleAddEditItems(
                e,
                from?.value || null,
                to?.value || null,
                handleClose,
                paymentPorpose?.value || null
              )
            }
          >
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                From
              </label>
              <Select
                value={from}
                required
                onChange={setFrom}
                options={
                  userList?.map((item) => ({
                    value: item.code,
                    label: item.code, //+ "-" + item.name,
                  })) || []
                }
                isOptionDisabled={(option) => option.value == to?.value}
                placeholder="Select From"
                isSearchable
                isClearable
              />
            </div>
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                To
              </label>
              <Select
                value={to}
                required
                onChange={setTo}
                options={
                  userList?.map((item) => ({
                    value: item.code,
                    label: item.code, //+ "-" + item.name,
                  })) || []
                }
                isOptionDisabled={(option) => option.value == from?.value}
                placeholder="Select To"
                isSearchable
                isClearable
              />
            </div>
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Purpose Of Payment
              </label>
              <Select
                value={paymentPorpose}
                required
                onChange={(selectedOption) => {
                  if (selectedOption?.value === "add_new") {
                    setShowAddPurposeModal(true);
                  } else {
                    setPaymentPorpose(selectedOption);
                  }
                }}
                options={[
                  { value: "add_new", label: "+ Add Other" },
                  ...ledgerList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  })),
                ]}
                placeholder="Select Purpose"
                isSearchable
                isClearable
              />
            </div>

            <div className="mb-2">
              <button
                disabled={isUpdatingItem}
                type="submit"
                className="button button-brown w-100"
              >
                {isUpdatingItem ? <Loader /> : "Save"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddPurposeModal}
        onHide={() => setShowAddPurposeModal(false)}
        animation={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Purpose</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveNewPurpose();
            }}
          >
            <div className="mb-2">
              <label htmlFor="newPurposeName" className="mb-10 d-block gray">
                Purpose Name
              </label>
              <input
                type="text"
                id="newPurposeName"
                value={newPurposeName}
                onChange={(e) => setNewPurposeName(e.target.value)}
                required
                className="form-control"
              />
            </div>
            <div className="mb-2">
              <button type="submit" className="button button-brown w-100">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddPaymentPurposeModal;
