import { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import AddArticleGstModal from "../components/AddArticleGstModal";
import * as XLSX from "xlsx";
import AddPaymentPurposeModal from "../components/AddPuprposeOfPaymentModal";
import { Modal } from "react-bootstrap";
import { isHo } from "../utils/comman";

function PurposeOfPayment() {
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCloseAddItem = () => setShowAddItem(false);
  const handleShowAddItem = (data) => setShowAddItem(data);

  const navigate = useNavigate();
  const componentRef = useRef();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getpaymentpurpose`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      setRows(response?.data?.data);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }
  async function handleDelete(row) {
    setIsDeleting(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/deletepaymentpurpose`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          id: row?.id,
        },
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setShowDeleteModal(false);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      getRows();
      setIsDeleting(false);
      // setPage("0");
    }
  }

  async function printOrExportData(isPrint, rows) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      let tableData;
      if (isPrint && rows?.length) {
        tableData = rows;
      } else {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himreport/getpaymentpurpose`,
          method: "GET",
          headers: {
            jwt_token: token,
          },
        });
        tableData = response?.data?.data;
      }

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
        <html>
          <head>
            <title>Print</title>
            <style>
            body {
              font-family: Arial, sans-serif;
            }
            .table-wrapper {
              width: 100%;
              border-collapse: collapse;
            }
            .table-wrapper th, .table-wrapper td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
            }
            .table-wrapper th {
              background-color: #f2f2f2;
            }
            .button {
              padding: 10px 20px;
              background-color: #AA731F;
              color: #fff;
              border: none;
              cursor: pointer;
              margin-top: 10px;
              border-radius: 5px;
            }
            .button:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }
            #selectAll {
              margin-right: 5px;
            }
            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              // margin-left: 20px;
              margin-right: 20px;
            }
          </style>
          </head>
          <body>
          <div class="heading">
            <h1>Purpose Of Payment</h1>
            <button id="printButton" class="button">Print</button>
          </div>
            <table class="table-wrapper">
              <thead>
                <tr>
                  <th><input checked type="checkbox" id="selectAll"></th>
                  <th>Sr. No.</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Purpose </th>
                </tr>
              </thead>
              <tbody>
                               ${tableData
                                 .map(
                                   (row, index) => `
                      <tr>
                        <td><input checked type="checkbox" class="rowCheckbox"></td>
                        <td class="sr-no">${index + 1}</td>
                        <td>${row?.from_code || ""}</td>
                        <td>${row?.to_code || ""}</td>
                        <td>${row?.payment_purpose?.name}</td>
                      </tr>
                    `
                                 )
                                 .join("")}
              </tbody>
            </table>
             <script>
            const printButton = document.getElementById('printButton');
            const checkboxes = document.querySelectorAll('.rowCheckbox');
            const selectAllCheckbox = document.getElementById('selectAll');

            function updatePrintButtonState() {
              const anyChecked = Array.from(checkboxes).some(
                (checkbox) => checkbox.checked
              );
              printButton.disabled = !anyChecked;
            }
              function updateSerialNumbers() {
                const visibleRows = Array.from(document.querySelectorAll('tbody tr')).filter(row => row.style.display !== 'none');
                visibleRows.forEach((row, index) => {
                  row.querySelector('.sr-no').textContent = index + 1;
                });
                }                     
            // Event listener for individual row checkboxes
            checkboxes.forEach((checkbox) => {
              checkbox.addEventListener('change', () => {
                updatePrintButtonState();
                // If any checkbox is unchecked, uncheck the 'Select All' checkbox
                if (!checkbox.checked) {
                  selectAllCheckbox.checked = false;
                }
              });
            });

            // Event listener for 'Select All' checkbox
            selectAllCheckbox.addEventListener('change', () => {
              const isChecked = selectAllCheckbox.checked;
              checkboxes.forEach((checkbox) => {
                checkbox.checked = isChecked;
              });
              updatePrintButtonState();
            });

            // Event listener for 'Print' button
            printButton.addEventListener('click', () => {
              // Hide unchecked rows
              checkboxes.forEach((checkbox) => {
                if (!checkbox.checked) {
                  checkbox.parentElement.parentElement.style.display = 'none';
                }
              });
              updateSerialNumbers();
              // Remove checkboxes column and the 'Print' button
              document.querySelectorAll('input[type="checkbox"]').forEach((el) => 
                 el.parentElement.remove()
                );
              printButton.remove();
              // Trigger print dialog
              window.print();
              // Close the window after printing or cancelling the print dialog
              window.close();
            });
          </script>
          </body>
        </html>
      `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr. No.": index + 1,
            From: item?.from_code || "",
            To: item?.to_code || "",
            "Purpose Of Payment": item?.payment_purpose?.name || "",
          };

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `purpose_of_payment.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function handleAddEditItem(
    event,
    from,
    to,
    handleCloseCallback,
    purpose
  ) {
    event && event?.preventDefault();

    setIsUpdatingItem(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        from_code: from,
        to_code: to,
        ledger_head_id: purpose,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/addpaymentpurpose`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddItem();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      getRows();
      setIsUpdatingItem(false);
    }
  }
  useEffect(() => {
    getRows();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Purpose Of Payment</h1>
          <div className="ms-auto">
            {isHo() && (
              <button
                className="button button-brown px-4 mx-4"
                type="button"
                onClick={() => handleShowAddItem(true)}
              >
                Add
              </button>
            )}
            {/* <button
              type="button"
              className="button button-brown mr-30 ml-30"
              onClick={() => printOrExportData(true)}
              disabled={!rows.length || isPrinting}
            >
              {isPrinting ? <Loader /> : "Print"}
            </button> */}
            <select
              value={""}
              onChange={(e) => {
                if (e.target.value == "Print Selection") {
                  printOrExportData(true, rows);
                } else if (e.target.value == "Print All") {
                  printOrExportData(true);
                }
              }}
              className="button button-brown mr-30"
            >
              <option className="d-none" value="">
                {isPrinting ? <Loader /> : "Print"}
              </option>
              <option value="Print Selection">Print Selection</option>
              {/* <option value="Print All">Print All</option> */}
            </select>
          </div>
        </div>

        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> From </th>
                <th> To </th>
                <th> Purpose Of Payment </th>
                {isHo() && <th></th>}
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>{row?.from_code}</td>
                    <td>{row?.to_code}</td>
                    <td>{row?.payment_purpose?.name}</td>
                    {isHo() && (
                      <td>
                        <button
                          className="button  px-2"
                          type="button"
                          onClick={() => {
                            setShowDeleteModal(row);
                          }}
                        >
                          <svg className="icon fs-22 text-danger">
                            <use href="#icon_delete"></use>
                          </svg>
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddPaymentPurposeModal
        showAddItem={showAddItem}
        isUpdatingItem={isUpdatingItem}
        handleAddEditItems={handleAddEditItem}
        handleCloseAddItem={handleCloseAddItem}
      />
      <Modal
        show={showDeleteModal}
        centered
        onHide={() => {
          setShowDeleteModal(false);
        }}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <h3>Are you sure you want to delete this record?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button "
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            No
          </button>
          <button
            className="button button-brown"
            onClick={() => {
              handleDelete(showDeleteModal);
            }}
            disabled={isDeleting}
          >
            {isDeleting ? <Loader /> : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PurposeOfPayment;
