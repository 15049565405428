import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Button } from "antd";
import Select from "react-select";
import axios from "axios";
import ApexPieChart from "./ApexPieChart";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function CashTrackPieChartModal({
  showChart,
  setShowChart,
  selectedFromDate,
  selectedToDate,
  selectedToCode,
  selectedFromCode,
  fromTouserList,
}) {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [fromCode, setFromCode] = useState();
  const [toCode, setToCode] = useState();
  const [statusPieList, setStatusPieList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (showChart) {
      fetchPieChartData(
        selectedFromCode,
        selectedToCode,
        selectedFromDate,
        selectedToDate
      );
      setFromCode(selectedFromCode);
      setToCode(selectedToCode);
      setFromDate(selectedFromDate);
      setToDate(selectedToDate);
    }
  }, [showChart]);

  const fetchPieChartData = async (fromCode, toCode, fromDate, toDate) => {
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/cashtrack/piechart`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          user_code: fromCode?.value || null,
          to_code: toCode?.value || null,
        },
      });
      setStatusPieList(response.data?.data || []);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      console.error("An error occurred while fetching pie chart data:", error);
    }
  };

  function handleCloseSuccess() {
    setShowSuccess(false);
    setSuccessMessagePopup("");
  }

  return (
    <>
      <Modal
        title="Cash Track Pie Chart"
        open={showChart}
        onCancel={() => setShowChart(false)}
        destroyOnClose={true}
        width={"69%"}
        // height={"80%"}
        footer={null}
      >
        <div className="d-flex gap-4 align-items-end ">
          <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              Date
            </label>
            <RangePicker
              className="input-box gray"
              value={[fromDate, toDate]}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                setFromDate(dates?.length ? dates[0] : "");
                setToDate(dates?.length ? dates[1] : "");
              }}
            />
          </div>
          <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              From
            </label>
            <Select
              value={fromCode}
              onChange={(value) => setFromCode(value)}
              options={fromTouserList.map((user) => ({
                value: user?.code,
                label: user?.code,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              To
            </label>
            <Select
              value={toCode}
              onChange={(value) => setToCode(value)}
              options={fromTouserList.map((user) => ({
                value: user?.code,
                label: user?.code,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <Button
            type="primary"
            onClick={() =>
              fetchPieChartData(fromCode, toCode, fromDate, toDate)
            }
          >
            Refresh
          </Button>
        </div>
        <hr className="my-10" />
        <div className="d-flex justify-content-center ">
          <ApexPieChart statusPieList={statusPieList} />
        </div>
      </Modal>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default CashTrackPieChartModal;
