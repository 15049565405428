import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
      }}
      className="container d-flex align-items-center justify-content-center flex-column "
    >
      <h1>Unauthorized</h1>
      <p>You are not authorized to view this page</p>
      {/* go back button*/}
      <button
        className="button button-brown"
        onClick={() => {
          navigate("/");
        }}
      >
        Dashboard
      </button>
    </div>
  );
};

export default Unauthorized;
