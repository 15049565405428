import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

function Export() {
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  const exportData = async () => {
    setIsExporting(true);
    try {
      const token = localStorage.getItem("him_user_token");
      console.log(token);
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/exportdata`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          from_date: selectedFromDate
            ? selectedFromDate.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate.format("YYYY-MM-DD") : null,
        },
      });

      if (response.data.success) {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_BASE_URL}/${response.data.data.zipPath}`;
        link.download = "exported_data.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <div className="inner-box">
        <div>
          <h1 className="heading">Export</h1>
          <div className="ms-auto">
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <div className="d-flex ">
                <RangePicker
                  className="input-box gray w-50"
                  value={[selectedFromDate, selectedToDate]}
                  format={"DD MMM YYYY"}
                  onChange={(dates) => {
                    setSelectedFromDate(dates?.length ? dates[0] : "");
                    setSelectedToDate(dates?.length ? dates[1] : "");
                  }}
                />
                <button
                  type="button"
                  className="button button-brown ms-auto"
                  onClick={exportData}
                  disabled={isExporting || !selectedFromDate || !selectedToDate}
                >
                  {isExporting ? "Exporting..." : "Export"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default Export;
