import React, { useEffect, useState } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import AsideBar from "./AsideBar";
import NavBar from "./NavBar";
import axios from "axios";
import { ConfigProvider } from "antd";
import Loader from "./Loader";
import { isHo } from "../utils/comman";

function LayoutOutlet() {
  const navigate = useNavigate();
  const matches = useMatches();
  const menu_key = matches[matches.length - 1]?.handle?.menu_key;
  const [reports, setReports] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  // const isAuthorized = true;

  useEffect(() => {
    // if (!isAuthorized) {
    //   return;
    // }

    if (
      !localStorage.getItem("him_user_token") ||
      !localStorage.getItem("him_user_data")
    ) {
      navigate("/login");
      return;
    }

    async function getAllReports() {
      try {
        const token = localStorage.getItem("him_user_token");

        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getreportslist`,
          method: "GET",
          headers: {
            jwt_token: token,
          },
        });

        if (response.status === 200) {
          let reports = JSON.stringify(response.data?.data);
          setReports(response.data?.data);
          localStorage.setItem("him_reports", reports);
        }
      } catch (error) {
        if (error?.response?.status == 403) {
          localStorage.removeItem("him_user_token");
          localStorage.removeItem("him_user_data");
          navigate("/login");
          return;
        }

        if (error?.code === "ECONNABORTED") {
          console.log("Request timed out");
        } else {
          console.log("An error occurred:", error);
        }
      }
    }
    async function getAllMenus() {
      // try {
      // const token = localStorage.getItem("him_user_token");
      const allowedReports =
        JSON.parse(localStorage.getItem("him_user_data"))?.allowed_reports ||
        [];

      // const response = await axios({
      //   url: `${process.env.REACT_APP_BASE_URL}/himuser/getMenu`,
      //   method: "GET",
      //   headers: {
      //     jwt_token: token,
      //   },
      // });

      // if (response.status === 200) {
      // const fetchedMenus = response.data?.data;
      // const mappedMenus = fetchedMenus.map((menu) => ({
      //   ...menu,
      //   is_allowed: allowedReports.includes(menu.id),
      // }));

      setMenus(allowedReports);
      const hasAccess = allowedReports
        ?.filter((report) => report?.status == 1)
        ?.some((menu) => menu.menu_key == menu_key);
      if (!hasAccess && menu_key !== "dashboard" && !isHo()) {
        navigate("/unauthorized");
        // setIsAuthorized(false);
      } else {
        setIsAuthorized(true);
      }
      // }
      // }
      // catch (error) {
      //   if (error?.response?.status == 403) {
      //     localStorage.removeItem("him_user_token");
      //     localStorage.removeItem("him_user_data");
      //     navigate("/login");
      //     return;
      //   }

      //   if (error?.code === "ECONNABORTED") {
      //     console.log("Request timed out");
      //   } else {
      //     console.log("An error occurred:", error);
      //   }
      // }
    }

    getAllReports();
    getAllMenus();
  }, [menu_key]);

  if (!isAuthorized) {
    return <Loader />; // or a loading spinner
  }

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#c8a366",
          },
        }}
      >
        <AsideBar menus={menus || []} reports={reports || []} />
        <div className="wrapper">
          {/* <NavBar/> */}
          <Outlet context={{ reports: reports || [] }}></Outlet>
        </div>
      </ConfigProvider>
    </>
  );
}

export default LayoutOutlet;
