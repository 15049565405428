import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import moment from "moment";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CashTrackPieChartModal from "../components/CashTrackPieChartModal";
const { RangePicker } = DatePicker;

function CashTrackOffice() {
  let columns = [
    {
      name: "Date",
      code: "date",
      format: (value) => (value ? moment(value).format("DD MMM YYYY") : ""),
    },
    {
      name: "From Code",
      code: "from_code",
    },
    {
      name: "To Code",
      code: "to_code",
    },
    // {
    //   name: "Payment Type",
    //   code: "payment_type",
    //   format: (value) => {
    //     switch (value) {
    //       case 1:
    //         return "Payment";
    //       case 2:
    //         return "Receipt";
    //       default:
    //         return value;
    //     }
    //   },
    // },
    // {
    //   name: "Amount",
    //   code: "amount",
    // },
    {
      name: "Amount Paid",
      code: "debit_amount",
      // format: (value) => (value && value > 0 ? value : ""),
    },
    {
      name: "Amount Received",
      code: "credit_amount",
      // format: (value) => (value && value > 0 ? value : ""),
    },
    {
      name: "Balance In Hand",
      code: "wallet_bal",
      // format: (value) => (value && value > 0 ? value : ""),
      // format: (value) => "",
    },
    {
      // name: "Ledger Head",
      name: "Purpose Of Payment",
      code: "ledger_head_code",
      format: (value) => (value ? value : ""),
    },
    {
      name: "Site",
      code: "contact",
      format: (value) =>
        value?.code
          ? value?.code
          : //  + "-" + value?.name
            "",
    },
    {
      name: "Mismatched",
      code: "mismatched",
      // format: (value) => (value && value > 0 ? value : ""),
    },
  ];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(true);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedSite, setSelectedSite] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState(dayjs());
  const [selectedToDate, setSelectedToDate] = useState(dayjs());
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [otherData, setOtherData] = useState({});
  const [siteList, setSiteList] = useState([]);

  const [selectedLedger, setSelectedLedger] = useState();
  const [ledgerList, setLedgerList] = useState([]);

  const [selectedFromCode, setSelectedFromCode] = useState({
    value: "H000",
    label: "H000",
  });
  const [selectedToCode, setSelectedToCode] = useState();
  const [fromTouserList, setFromToUserList] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const navigate = useNavigate();
  const componentRef = useRef();
  async function getRows(
    selectedSite,
    selectedFromDate,
    selectedToDate,
    selectedToCode,
    selectedFromCode,
    selectedLedger
  ) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getdailytransactionweb`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          // user_code: selectedUser?.value || "H000",
          user_code: selectedFromCode?.value, //|| "H000",
          site_code: selectedSite?.value || null,
          to_code: selectedToCode?.value || null,
          // from_code: selectedFromCode?.value || "H000",
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          ledger_head: selectedLedger?.value || null,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
        },
      });

      let data = response?.data?.data || [];
      //data should be and array of objects which will be filterd out based on date and to_code means if array contains any object with same date and to_code then the credit_amount and debit_amount should be added and only one object should be there with the sum of credit_amount and debit_amount
      let filteredData = [];
      data.forEach((item) => {
        let index = filteredData.findIndex(
          (x) => x.date === item.date && x.to_code === item.to_code
        );
        if (index === -1) {
          filteredData.push({
            ...item,
            balance_in_hand:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
            mismatched:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
          });
        } else {
          filteredData[index].credit_amount =
            parseFloat(filteredData[index].credit_amount) +
            parseFloat(item.credit_amount);
          filteredData[index].debit_amount =
            parseFloat(filteredData[index].debit_amount) +
            parseFloat(item.debit_amount);
          filteredData[index].balance_in_hand =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
          filteredData[index].mismatched =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
        }
      });
      data = filteredData;
      setRows(data);
      setTotalCount(response?.data?.count);
      setOtherData({
        total_amount_received: response?.data?.total_received,
        total_amount_paid: response?.data?.total_paid,
        wallet_balance: response?.data?.wallet_bal,
      });
      // setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  // async function printOrExportData(
  //   isPrint,
  //   selectedUser,
  //   selectedFromDate,
  //   selectedToDate
  // ) {
  //   try {
  //     if (isPrint) {
  //       setIsPrinting(true);
  //     } else {
  //       setIsExporting(true);
  //     }
  //     const token = localStorage.getItem("him_user_token");
  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/getdailytransactionweb`,
  //       method: "POST",
  //       headers: {
  //         jwt_token: token,
  //       },
  //       data: {
  //         // user_code: selectedUser?.value || null,
  //         user_code: "H000",
  //         from_date: selectedFromDate
  //           ? selectedFromDate?.format("YYYY-MM-DD")
  //           : null,
  //         to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
  //         paginate: false,
  //       },
  //     });

  //     let tableData = response?.data?.data || [];
  //     //data should be and array of objects which will be filterd out based on date and to_code means if array contains any object with same date and to_code then the credit_amount and debit_amount should be added and only one object should be there with the sum of credit_amount and debit_amount
  //     let filteredData = [];
  //     tableData.forEach((item) => {
  //       let index = filteredData.findIndex(
  //         (x) => x.date === item.date && x.to_code === item.to_code
  //       );
  //       if (index === -1) {
  //         filteredData.push({
  //           ...item,
  //           balance_in_hand:
  //             parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
  //           mismatched:
  //             parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
  //         });
  //       } else {
  //         filteredData[index].credit_amount =
  //           parseFloat(filteredData[index].credit_amount) +
  //           parseFloat(item.credit_amount);
  //         filteredData[index].debit_amount =
  //           parseFloat(filteredData[index].debit_amount) +
  //           parseFloat(item.debit_amount);
  //         filteredData[index].balance_in_hand =
  //           parseFloat(filteredData[index].debit_amount) -
  //           parseFloat(filteredData[index].credit_amount);
  //         filteredData[index].mismatched =
  //           parseFloat(filteredData[index].debit_amount) -
  //           parseFloat(filteredData[index].credit_amount);
  //       }
  //     });
  //     tableData = filteredData;
  //     if (isPrint) {
  //       // Open a new window
  //       const printWindow = window.open("", "");

  //       // Generate HTML content for the new window
  //       const tableHtml = `
  //       <html>
  //         <head>
  //           <title>Print</title>
  //           <style>
  //             body {
  //               font-family: Arial, sans-serif;
  //             }
  //             .table-wrapper {
  //               width: 100%;
  //               border-collapse: collapse;
  //             }
  //             .table-wrapper th, .table-wrapper td {
  //               border: 1px solid #000;
  //               padding: 8px;
  //               text-align: left;
  //             }
  //             .table-wrapper th {
  //               background-color: #f2f2f2;
  //             }
  //             @media print {
  //               @page {
  //                 size: A4 landscape;
  //                 margin: 15mm;
  //               }
  //             }
  //           </style>
  //         </head>
  //         <body>
  //           <h1>Cash Track</h1>
  //           <table class="table-wrapper">
  //             <thead>
  //               <tr>
  //                 <th>Sr. No.</th>
  //                 ${columns.map((column) => `<th>${column.name}</th>`).join("")}
  //               </tr>
  //             </thead>
  //             <tbody>

  //                              ${tableData
  //                                .map((item, index) => {
  //                                  return `<tr>
  //                                   <td>${index + 1}</td>
  //                                   ${columns
  //                                     .map((column) => {
  //                                       return `<td>${
  //                                         column.format
  //                                           ? column.format(item[column.code])
  //                                           : item[column.code]
  //                                       }</td>`;
  //                                     })
  //                                     .join("")}
  //                                 </tr>`;
  //                                })
  //                                .join("")}

  //             </tbody>
  //           </table>
  //           <script>
  //             window.onload = function() {
  //               window.print();
  //               window.onafterprint = window.close;
  //             };
  //           </script>
  //         </body>
  //       </html>
  //     `;

  //       // Write content to the new window
  //       printWindow.document.open();
  //       printWindow.document.write(tableHtml);
  //       printWindow.document.close();
  //     } else {
  //       const exportData = tableData.map((item, index) => {
  //         const data = {
  //           "Sr.No.": parseInt(page) * limit + index + 1,
  //         };
  //         columns.forEach((column) => {
  //           data[column.name] = column.format
  //             ? column.format(item[column.code])
  //             : item[column.code];
  //         });

  //         return data;
  //       });
  //       const worksheet = XLSX.utils.json_to_sheet(exportData);
  //       const workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //       XLSX.writeFile(workbook, `cash_track_office.xlsx`);
  //     }
  //   } catch (error) {
  //     setShowErrModal(true);
  //     setError({ title: "Error", err: error?.message });

  //     if (error?.response?.status == 403) {
  //       console.log("error", error);
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   } finally {
  //     if (isPrint) {
  //       setIsPrinting(false);
  //     } else {
  //       setIsExporting(false);
  //     }
  //   }
  // }
  async function printOrExportData(
    isPrint,
    selectedSite,
    selectedFromDate,
    selectedToDate,
    rows,
    selectedToCode,
    selectedFromCode,
    selectedLedger
  ) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      let tableData;
      if (isPrint && rows?.length) {
        tableData = rows;
      } else {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himreport/getdailytransactionweb`,
          method: "POST",
          headers: {
            jwt_token: token,
          },
          data: {
            user_code: selectedFromCode?.value || null,
            ledger_head: selectedLedger?.value || null,
            site_code: selectedSite?.value || null,
            to_code: selectedToCode?.value || null,
            from_date: selectedFromDate
              ? selectedFromDate?.format("YYYY-MM-DD")
              : null,
            to_date: selectedToDate
              ? selectedToDate?.format("YYYY-MM-DD")
              : null,
            paginate: false,
          },
        });
        tableData = response?.data?.data;
      }

      // Filter and aggregate data
      let filteredData = [];
      tableData.forEach((item) => {
        let index = filteredData.findIndex(
          (x) => x.date === item.date && x.to_code === item.to_code
        );
        if (index === -1) {
          filteredData.push({
            ...item,
            balance_in_hand:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
            mismatched:
              parseFloat(item.debit_amount) - parseFloat(item.credit_amount),
          });
        } else {
          filteredData[index].credit_amount =
            parseFloat(filteredData[index].credit_amount) +
            parseFloat(item.credit_amount);
          filteredData[index].debit_amount =
            parseFloat(filteredData[index].debit_amount) +
            parseFloat(item.debit_amount);
          filteredData[index].balance_in_hand =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
          filteredData[index].mismatched =
            parseFloat(filteredData[index].debit_amount) -
            parseFloat(filteredData[index].credit_amount);
        }
      });
      tableData = filteredData;

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
                .table-wrapper {
                  width: 100%;
                  border-collapse: collapse;
                }
                .table-wrapper th, .table-wrapper td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
                .table-wrapper th {
                  background-color: #f2f2f2;
                }
                .button {
                  padding: 10px 20px;
                  background-color: #AA731F;
                  color: #fff;
                  border: none;
                  cursor: pointer;
                  margin-top: 10px;
                  border-radius: 5px;
                }
                .button:disabled {
                  background-color: #ccc;
                  cursor: not-allowed;
                }
                #selectAll {
                  margin-right: 5px;
                }
                .heading {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
                  margin-right: 20px;
                }
              </style>
            </head>
            <body>
              <div class="heading">
                <h1>Cash Track</h1>
                <button id="printButton" class="button">Print</button>
              </div>
              <table class="table-wrapper">
                <thead>
                  <tr>
                    <th><input checked type="checkbox" id="selectAll"></th>
                    <th >Sr. No.</th>
                    ${columns
                      .map((column) => `<th>${column.name}</th>`)
                      .join("")}
                  </tr>
                </thead>
                <tbody>
                  ${tableData
                    .map((item, index) => {
                      return `<tr>
                        <td><input checked type="checkbox" class="rowCheckbox"></td>
                        <td class="sr-no">${index + 1}</td>
                        ${columns
                          .map((column) => {
                            return `<td>${
                              column.format
                                ? column.format(item[column.code])
                                : item[column.code]
                            }</td>`;
                          })
                          .join("")}
                      </tr>`;
                    })
                    .join("")}
                </tbody>
              </table>
              <script>
                const printButton = document.getElementById('printButton');
                const checkboxes = document.querySelectorAll('.rowCheckbox');
                const selectAllCheckbox = document.getElementById('selectAll');
  
                function updatePrintButtonState() {
                  const anyChecked = Array.from(checkboxes).some(
                    (checkbox) => checkbox.checked
                  );
                  printButton.disabled = !anyChecked;
                }

                 function updateSerialNumbers() {
                const visibleRows = Array.from(document.querySelectorAll('tbody tr')).filter(row => row.style.display !== 'none');
                visibleRows.forEach((row, index) => {
                  row.querySelector('.sr-no').textContent = index + 1;
                });
                }
  
                checkboxes.forEach((checkbox) => {
                  checkbox.addEventListener('change', () => {
                    updatePrintButtonState();
                    if (!checkbox.checked) {
                      selectAllCheckbox.checked = false;
                    }
                  });
                });
  
                selectAllCheckbox.addEventListener('change', () => {
                  const isChecked = selectAllCheckbox.checked;
                  checkboxes.forEach((checkbox) => {
                    checkbox.checked = isChecked;
                  });
                  updatePrintButtonState();
                });
  
                printButton.addEventListener('click', () => {
                  checkboxes.forEach((checkbox) => {
                    if (!checkbox.checked) {
                      checkbox.parentElement.parentElement.style.display = 'none';
                    }
                  });
                  updateSerialNumbers();
                  document.querySelectorAll('input[type="checkbox"]').forEach((el) => 
                    el.parentElement.remove()
                  );
                  printButton.remove();
                  window.print();
                  window.close();
                });
              </script>
            </body>
          </html>
        `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
          };
          columns.forEach((column) => {
            data[column.name] = column.format
              ? column.format(item[column.code])
              : item[column.code];
          });

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `cash_track_office.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: type,
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  async function getLedgerList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/payment_purpose`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return setLedgerList(response.data?.data || []);
      } else {
        return setLedgerList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    getRows(
      selectedSite,
      selectedFromDate,
      selectedToDate,
      selectedToCode,
      selectedFromCode,
      selectedLedger
    );
  }, [page, limit]);
  useEffect(() => {
    (async () => {
      let siteList = await getContractors("office");
      setSiteList(siteList);
      let fromToUserList = await getContractors(
        "Employee,Head Office,Vendor,Contractor,Subcontractor,Director,Firms,Refund,Refundable"
      );
      setFromToUserList(fromToUserList);
    })();
    getLedgerList();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Cash Track </h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between gap-4">
          <div className="d-flex gap-4 align-items-end">
            {/* <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose User
              </label>
              <Select
                // className="input-box"
                value={selectedUser}
                onChange={(value) => {
                  setSelectedUser(value);
                  if (page == 0) {
                    getRows(value, selectedFromDate, selectedToDate);
                  } else {
                    setPage(0);
                  }
                }}
                options={userList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div> */}
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                From Date
              </label>
              <input
                value={selectedFromDate}
                onChange={(e) => setSelectedFromDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
            To Date
            </label>
            <input
            value={selectedToDate}
            onChange={(e) => setSelectedToDate(e.target.value)}
            type="date"
            className="input-box gray"
            />
            </div> */}
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <RangePicker
                className="input-box gray"
                value={[selectedFromDate, selectedToDate]}
                format={"DD MMM YYYY"}
                onChange={(dates) => {
                  setSelectedFromDate(dates?.length ? dates[0] : "");
                  setSelectedToDate(dates?.length ? dates[1] : "");
                  if (page == 0) {
                    getRows(
                      selectedSite,
                      dates?.length ? dates[0] : "",
                      dates?.length ? dates[1] : "",
                      selectedToCode,
                      selectedFromCode,
                      selectedLedger
                    );
                  } else {
                    setPage(0);
                  }
                }}
              />
            </div>
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Choose From
              </label>
              <Select
                // className="input-box"
                value={selectedFromCode}
                onChange={(value) => {
                  setSelectedFromCode(value);
                  if (page == 0) {
                    getRows(
                      selectedSite,
                      selectedFromDate,
                      selectedToDate,
                      selectedToCode,
                      value,
                      selectedLedger
                    );
                  } else {
                    setPage(0);
                  }
                }}
                options={fromTouserList.map((user) => ({
                  value: user?.code,
                  label: user?.code, //+ "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Choose To
              </label>
              <Select
                // className="input-box"
                value={selectedToCode}
                onChange={(value) => {
                  setSelectedToCode(value);
                  if (page == 0) {
                    getRows(
                      selectedSite,
                      selectedFromDate,
                      selectedToDate,
                      value,
                      selectedFromCode,
                      selectedLedger
                    );
                  } else {
                    setPage(0);
                  }
                }}
                options={fromTouserList.map((user) => ({
                  value: user?.code,
                  label: user?.code, //+ "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Choose Site
              </label>
              <Select
                // className="input-box"
                value={selectedSite}
                onChange={(value) => {
                  setSelectedSite(value);
                  if (page == 0) {
                    getRows(
                      value,
                      selectedFromDate,
                      selectedToDate,
                      selectedToCode,
                      selectedFromCode,
                      selectedLedger
                    );
                  } else {
                    setPage(0);
                  }
                }}
                options={siteList.map((user) => ({
                  value: user?.code,
                  label: user?.code, //+ "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Choose Purpose of Payment
              </label>
              <Select
                // className="input-box"
                value={selectedLedger}
                onChange={(value) => {
                  setSelectedLedger(value);
                  if (page == 0) {
                    getRows(
                      selectedSite,
                      selectedFromDate,
                      selectedToDate,
                      selectedToCode,
                      selectedFromCode,
                      value
                    );
                  } else {
                    setPage(0);
                  }
                }}
                options={ledgerList.map((user) => ({
                  value: user?.name,
                  label: user?.name, //+ "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
          </div>
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/* <button
              type="button"
              className="button button-brown mr-30"
              onClick={exportToExcel}
              disabled={!rows.length}
            >
              Export
            </button> */}
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={() => setShowChart(true)}
            >
              Pie Chart
            </button>
            <select
              value={""}
              onChange={(e) => {
                if (e.target.value == "Print Selection") {
                  printOrExportData(
                    true,
                    selectedSite,
                    selectedFromDate,
                    selectedToDate,
                    rows,
                    selectedToCode,
                    selectedFromCode,
                    selectedLedger
                  );
                } else if (e.target.value == "Print All") {
                  printOrExportData(
                    true,
                    selectedSite,
                    selectedFromDate,
                    selectedToDate,
                    null,
                    selectedToCode,
                    selectedFromCode,
                    selectedLedger
                  );
                }
              }}
              className="button button-brown mr-30"
            >
              <option className="d-none" value="">
                {isPrinting ? <Loader /> : "Print"}
              </option>
              <option value="Print Selection">Print Selection</option>
              <option value="Print All">Print All</option>
            </select>
          </div>
        </div>
        <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          {/* <div>
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              // onClick={handleShowAddCred}
            >
              Export
            </button>
          </div> */}
          {/* {selectedUser && (
            <>
              <div>
                <span className="gray">Total Amount Received:</span>{" "}
                {otherData?.total_amount_received}
              </div>
              <div>
                <span className="gray">Total Amount Paid:</span>{" "}
                {otherData?.total_amount_paid}
              </div>
               <div>
                <span className="gray">Wallet Balance:</span>{" "}
                {otherData?.wallet_balance}
              </div>
            </>
          )} */}
        </div>
        <div ref={componentRef} className="tbl-scroller">
          {!isGettingRows && rows?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  {columns.map((column) => (
                    <th key={column.code}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>{parseInt(page) * limit + index + 1}</td>
                    {columns.map((column) => (
                      <td key={column.code}>
                        {column.format
                          ? column.format(row[column.code])
                          : row[column.code]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {/* {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )} */}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <CashTrackPieChartModal
        showChart={showChart}
        setShowChart={setShowChart}
        selectedFromDate={selectedFromDate}
        selectedToDate={selectedToDate}
        selectedToCode={selectedToCode}
        selectedFromCode={selectedFromCode}
        fromTouserList={fromTouserList}
      />
    </>
  );
}

export default CashTrackOffice;
