import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ApexPieChart = ({ statusPieList, name }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0); // New state for total amount
  const options = {
    plotOptions: {
      pie: {
        customScale: 0.8,
        // Removed donut labels configuration
      },
    },
    chart: {
      type: "pie",
      // width: "20%",
      // height: 350,
    },

    dataLabels: {
      enabled: true,
    },

    legend: {
      position: "right",
      offsetY: 0,
      markers: {
        shape: "square",
      },
    },
    labels: labels,
  };

  useEffect(() => {
    const seriesData = statusPieList.map((item) =>
      parseFloat(item.total_amount)
    );
    const labelsData = statusPieList.map(
      (item) => `${item.to_code} : ${item.total_amount}`
    );
    setSeries(seriesData);
    setLabels(labelsData);
    setTotalAmount(seriesData.reduce((acc, curr) => acc + curr, 0)); // Calculate total amount
  }, [statusPieList]);

  return (
    <div
      // style={{
      //   maxHeight: 200,
      // }}
      className="chart-wrap  w-75"
    >
      <div id="chart">
        <p>{name ? name : ""}</p>
        {statusPieList.length ? (
          <>
            <ReactApexChart
              className="pie-chart"
              options={options}
              series={series}
              type="pie"
            />
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <strong>Total: </strong>
              {totalAmount.toFixed(2)}
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </div>
    </div>
  );
};

export default ApexPieChart;
