import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddEditTotalStaffDetails({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
}) {
  // "trans_date":"2024-10-10",
  //   "payment_type":"1",
  //   "from_code":"E001",
  //   "to_code":"V001",
  //   "amount":"1000"

  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [staffCode, setStaffCode] = useState();
  const [location, setLocation] = useState();
  const [cheque, setCheque] = useState();
  const [recBy, setRecBy] = useState();
  const [site, setSite] = useState();
  const [transType, setTransType] = useState();
  const [paymentType, setPaymentType] = useState();
  const [ledgerHead, setLedgerHead] = useState();
  const [duties, setDuties] = useState("");
  const [salaryDaily, setSalaryDaily] = useState("");
  const [reumbursment, setReumbursment] = useState("");
  const [performance, setPerformance] = useState();
  const [salaryPerformance, setSalaryPerformance] = useState("");
  const [vehicleAllocated, setVehicleAllocated] = useState();
  const [noOfWorkingDays, setNoOfWorkingDays] = useState("");
  const [remarks, setRemarks] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const handleClose = () => {
    setDate("");
    setStaffCode("");
    setDuties("");
    setSalaryDaily("");
    setReumbursment("");
    setPerformance("");
    setSalaryPerformance("");
    setVehicleAllocated("");
    setNoOfWorkingDays("");
    setRemarks("");
    handleCloseAddCred();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getGradelist() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getgradelist`,
        method: "GET",

        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data || [];
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setDate(showAddCred?.date);
      setDuties(showAddCred?.duties_assigned);
      setSalaryDaily(showAddCred?.salary_daily);
      setReumbursment(showAddCred?.reimbursement);
      setSalaryPerformance(showAddCred?.salary_performance);
      setNoOfWorkingDays(showAddCred?.no_of_working_days);
      setRemarks(showAddCred?.remarks);
    }
  }, [showAddCred]);

  useEffect(() => {
    (async () => {
      let users = await getContractors("Office");
      setLocationList(users);
      let employeeList = await getContractors("Employee");
      setEmployeeList(employeeList);
      let gradeList = await getGradelist();
      setGradeList(gradeList);
      let vehicleList = await getContractors("Vehicle");
      setVehicleList(vehicleList);
      // setBankList(bankList);
      if (showAddCred?.id) {
        let staff = employeeList.find(
          (item) => item.code === showAddCred?.staff_code
        );
        let location = users.find(
          (item) => item.code === showAddCred?.location
        );
        let grade = gradeList.find(
          (item) => item.grade === showAddCred?.performance
        );
        let vehicle = vehicleList.find(
          (item) => item.code === showAddCred?.vehicle_allocated
        );
        setStaffCode(
          staff
            ? {
                label: staff?.code,
                // + "-" + staff?.name
                value: staff?.code,
              }
            : {
                label: showAddCred?.staff_code,
                value: showAddCred?.staff_code,
              }
        );
        setLocation(
          location
            ? {
                label: location?.code,
                // + "-" + location?.name
                value: location?.code,
              }
            : {
                label: showAddCred?.location,
                value: showAddCred?.location,
              }
        );
        setPerformance(
          grade
            ? {
                label: grade?.grade + "-" + grade?.grade_percent,
                value: grade?.grade,
                grade_percent: grade?.grade_percent,
              }
            : {
                label: showAddCred?.performance,
                value: showAddCred?.performance,
              }
        );
        setVehicleAllocated(
          vehicle
            ? {
                label: vehicle?.code,
                // + "-" + vehicle?.name
                value: vehicle?.code,
              }
            : {
                label: showAddCred?.vehicle_allocated,
                value: showAddCred?.vehicle_allocated,
              }
        );
      }
    })();
  }, [showAddCred]);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              showAddCred?.id || null,
              date,
              staffCode?.value,
              duties,
              location?.value,
              salaryDaily,
              reumbursment,
              performance?.value,
              salaryPerformance,
              vehicleAllocated?.value,
              noOfWorkingDays,
              remarks,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Date
            </label>
            <input
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="input-box"
              placeholder="Select Date"
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Staff Code
            </label>

            <Select
              // className="input-box"
              value={staffCode}
              required
              onChange={async (option) => {
                setStaffCode(option);
              }}
              options={employeeList.map((user) => ({
                value: user?.code,
                label: user?.code,
                // + "-" + user?.name
              }))}
              placeholder="Select From Code"
              isSearchable
              isClearable
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Duties Assigned
            </label>
            <input
              required
              value={duties}
              onChange={(e) => setDuties(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Duties Assigned"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Location
            </label>

            <Select
              // className="input-box"
              value={location}
              required
              onChange={async (option) => {
                setLocation(option);
              }}
              options={locationList.map((user) => ({
                value: user?.code,
                label: user?.code,
                // + "-" + user?.name
              }))}
              placeholder="Select Location"
              isSearchable
              isClearable
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Salary Daily
            </label>
            <input
              required
              value={salaryDaily}
              onChange={(e) => {
                setSalaryDaily(e.target.value);
                let salaryPerformance =
                  parseFloat(e.target.value) *
                  parseFloat(performance?.grade_percent || 1);
                setSalaryPerformance(salaryPerformance);
              }}
              type="number"
              className="input-box"
              placeholder="Enter Salary Daily"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Reumbursment
            </label>
            <input
              required
              value={reumbursment}
              onChange={(e) => setReumbursment(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter Reumbursment"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Performance
            </label>

            <Select
              // className="input-box"
              value={performance}
              required
              onChange={async (option) => {
                setPerformance(option);
                let salaryPerformance =
                  parseFloat(salaryDaily) *
                  parseFloat(option?.grade_percent || 1);
                setSalaryPerformance(salaryPerformance);
              }}
              options={gradeList.map((user) => ({
                value: user?.grade,
                label: user?.grade + "-" + user?.grade_percent,
                grade_percent: user?.grade_percent,
              }))}
              placeholder="Select Performance"
              isSearchable
              isClearable
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Salary Performance
            </label>
            <input
              disabled
              required
              value={salaryPerformance}
              onChange={(e) => setSalaryPerformance(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter Salary Performance"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Vehicle Allocated
            </label>

            <Select
              // className="input-box"
              value={vehicleAllocated}
              required
              onChange={async (option) => {
                setVehicleAllocated(option);
              }}
              options={vehicleList.map((user) => ({
                value: user?.code,
                label: user?.code,
                // + "-" + user?.name
              }))}
              placeholder="Select Vehicle Allocated"
              isSearchable
              isClearable
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              No of Working Days
            </label>
            <input
              required
              value={noOfWorkingDays}
              onChange={(e) => setNoOfWorkingDays(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter No of Working Days"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Remarks
            </label>
            {/* textarea */}
            <textarea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className="input-box"
              placeholder="Enter Remarks"
            ></textarea>
          </div>

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditTotalStaffDetails;
