import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddWebCredentials({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
}) {
  const context = useOutletContext();
  // const reports = context?.reports || [];
  const navigate = useNavigate();
  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  const [selectedReports, setSelectedReports] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [reports, setReports] = useState([]);
  const [userType, setUserType] = useState({
    value: 2,
    label: "Head Office Manager",
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => {
    setUsername("");
    setPassword("");
    setSelectedReports([]);
    handleCloseAddCred();
    setUserType();
  };

  async function getMenuList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himuser/getMenu`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setReports(response.data?.data || []);
      } else {
        setReports([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getRoles() {
    try {
      const token = localStorage.getItem("him_user_token");

      // const response = await axios({
      //   url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getRoles`,
      //   method: "GET",
      //   headers: {
      //     jwt_token: token,
      //   },
      // });

      // if (response.status === 200) {
      setRoleList(
        // response.data?.data ||
        [
          {
            role_id: 1,
            role_name: "Head Office Admin",
          },
          {
            role_id: 2,
            role_name: "Head Office Manager",
          },
        ]
      );
      // } else {
      //   setRoleList([]);
      // }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  // async function getContractors() {
  //   try {
  //     const token = localStorage.getItem("him_user_token");

  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
  //       method: "GET",
  //       params: {
  //         ledger_master: "Vendor,Office,Contractor,Subcontractor",
  //       },
  //       headers: {
  //         jwt_token: token,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setSiteList(response.data?.data);
  //     } else {
  //       setSiteList([]);
  //     }
  //   } catch (error) {
  //     if (error?.response?.status == 403) {
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   }
  // }
  // async function getVehicles() {
  //   try {
  //     const token = localStorage.getItem("him_user_token");

  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/getvehicallist`,
  //       method: "GET",
  //       // params: {
  //       //   ledger_master: "Vehicle",
  //       // },
  //       headers: {
  //         jwt_token: token,
  //       },
  //     });

  //     if (response.status === 200) {
  //       setVehiclesList(response.data?.data);
  //     } else {
  //       setVehiclesList([]);
  //     }
  //   } catch (error) {
  //     if (error?.response?.status == 403) {
  //       localStorage.removeItem("him_user_token");
  //       localStorage.removeItem("him_user_data");
  //       navigate("/login");
  //       return;
  //     }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   }
  // }

  function handleMenuSelection(menuId, isChecked) {
    if (isChecked) {
      setSelectedReports((prevSelectedReports) => {
        let newSelectedReports = [...prevSelectedReports, menuId];
        const parentMenu = reports.find((report) =>
          report.submenu?.some((submenu) => submenu.id === menuId)
        );
        if (parentMenu && !newSelectedReports.includes(parentMenu.id)) {
          newSelectedReports.push(parentMenu.id);
        }
        const selectedParentMenu = reports.find(
          (report) => report.id === menuId
        );
        if (selectedParentMenu?.submenu?.length > 0) {
          selectedParentMenu.submenu.forEach((submenu) => {
            if (!newSelectedReports.includes(submenu.id)) {
              newSelectedReports.push(submenu.id);
            }
          });
        }
        return newSelectedReports;
      });
    } else {
      setSelectedReports((prevSelectedReports) => {
        let newSelectedReports = prevSelectedReports.filter(
          (item) => item !== menuId
        );
        const parentMenu = reports.find((report) =>
          report.submenu?.some((submenu) => submenu.id === menuId)
        );
        if (parentMenu) {
          const allSubmenusUnchecked = parentMenu.submenu.every(
            (submenu) => !newSelectedReports.includes(submenu.id)
          );
          if (allSubmenusUnchecked) {
            newSelectedReports = newSelectedReports.filter(
              (item) => item !== parentMenu.id
            );
          }
        }
        const selectedParentMenu = reports.find(
          (report) => report.id === menuId
        );
        if (selectedParentMenu?.submenu?.length > 0) {
          selectedParentMenu.submenu.forEach((submenu) => {
            newSelectedReports = newSelectedReports.filter(
              (item) => item !== submenu.id
            );
          });
        }
        return newSelectedReports;
      });
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setUsername(showAddCred?.web_user?.email);
      setPassword(showAddCred?.web_user?.pass_code);
      setSelectedReports(
        showAddCred?.web_report_access?.map((report) => report?.report_id) || []
      );

      if (showAddCred?.web_user?.role_id) {
        setUserType({
          value: showAddCred?.web_user?.role_id,
          label: roleList.find(
            (role) => role.role_id === showAddCred?.web_user?.role_id
          ).role_name,
        });
      }
    }
  }, [showAddCred]);

  useEffect(() => {
    getRoles();
    getMenuList();
    // getContractors();
    // getVehicles();
  }, [showAddCred]);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Web Credentials <br /> ({showAddCred?.code}
          {/* -{showAddCred?.name} */})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              username,
              password,
              selectedReports,
              showAddCred?.him_ledger_master?.name == "Head Office"
                ? "1"
                : userType?.value,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Username
            </label>
            <input
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Username"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Password
            </label>
            <input
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Password"
            />
          </div>
          {showAddCred?.him_ledger_master?.name != "Head Office" && (
            <>
              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Role
                </label>
                <Select
                  // className="input-box"
                  value={userType}
                  onChange={setUserType}
                  options={roleList.map((user) => ({
                    value: user?.role_id,
                    label: user?.role_name,
                  }))}
                  placeholder="Select Role"
                  isSearchable
                  isClearable
                />
              </div>

              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Select Menu
                </label>

                <div className="d-flex flex-column my-2">
                  {reports
                    ?.filter((report) => report?.status == 1)
                    ?.map((report) => (
                      <div
                        key={report?.id + "reportcheckbox"}
                        className="form-check border-bottom pb-2"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) =>
                            handleMenuSelection(report?.id, e.target.checked)
                          }
                          checked={selectedReports.includes(report?.id)}
                          value={report?.id}
                          id={report?.id + "id"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={report?.id + "id"}
                        >
                          {report?.menu_name}
                        </label>
                        {report?.submenu?.length > 0 && (
                          <div className="ml-4">
                            {report?.submenu
                              ?.filter((report) => report?.status == 1)
                              ?.map((submenu) => (
                                <div
                                  key={submenu?.id + "submenucheckbox"}
                                  className="form-check"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleMenuSelection(
                                        submenu?.id,
                                        e.target.checked
                                      )
                                    }
                                    checked={selectedReports.includes(
                                      submenu?.id
                                    )}
                                    value={submenu?.id}
                                    id={submenu?.id + "id"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={submenu?.id + "id"}
                                  >
                                    {submenu?.menu_name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}

          <div className="mb-2">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddWebCredentials;
