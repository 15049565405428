import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import Loader from "../components/Loader";
import Select from "react-select";
import { fromBase64UrlSafe, parseTo2 } from "../utils/comman";
import ErrorModal from "../components/ErrorModal";

function VendorCode() {
  // const [mobileForm, setmobileForm] = useState("measurements");
  const [selectedOption, setSelectedOption] = useState("measurements");
  const [siteList, setSiteList] = useState([]);
  const [vendor, setVendor] = useState();
  const [employee, setEmployee] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [itemsList, setItemsList] = useState([]);
  const [rows, setRows] = useState([
    {
      site_code: "",
      item_id: "",
      item_name: "",
      nos: "",
      length: "",
      breadth: "",
      height: "",
      qty: "",
      unit: "",
      rate: "",
      amount: "",
    },
  ]);
  const handleSelectChange = (event) => {
    if (event.target.value === "measurements") {
      setRows([
        {
          site_code: "",
          item_id: "",
          item_name: "",
          nos: "",
          length: "",
          breadth: "",
          height: "",
          qty: "",
          unit: "",
          rate: "",
          amount: "",
        },
      ]);
    } else {
      setRows([
        {
          site_code: "",
          item_id: "",
          item_name: "",
          qty: "",
          unit: "",
          rate: "",
          amount: "",
        },
      ]);
    }
    setSelectedOption(event.target.value);
    // setmobileForm(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { code, emp } = useParams();

  async function handleSubmit(event) {
    // event && event?.preventDefault();

    setIsLoading(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himapi/himvendorquote`,
        method: "POST",

        data: {
          emp_code: fromBase64UrlSafe(emp) || "",
          vendor_code: fromBase64UrlSafe(code) || "",
          quote_type: selectedOption,
          data: rows?.map((row) => {
            return {
              ...row,
              site_code: row.site_code?.value,
              item_id: row.item_id?.value,
              vendor_code: fromBase64UrlSafe(code) || "",
              emp_code: fromBase64UrlSafe(emp) || "",
              quote_type: selectedOption,
              qty:
                selectedOption === "measurements"
                  ? (!row?.nos ? 1 : parseFloat(row?.nos)) *
                    (!row?.length ? 1 : parseFloat(row?.length)) *
                    (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                    (!row?.height ? 1 : parseFloat(row?.height))
                  : row?.qty,
              // qty:
              //   selectedOption === "measurements"
              //     ? row?.nos * row?.length * row?.breadth * row?.height
              //     : row?.qty,
              amount:
                selectedOption === "measurements"
                  ? (!row?.nos ? 1 : parseFloat(row?.nos)) *
                    (!row?.length ? 1 : parseFloat(row?.length)) *
                    (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                    (!row?.height ? 1 : parseFloat(row?.height)) *
                    row?.rate
                  : (row?.qty * row?.rate).toFixed(2),
              // amount:
              //   selectedOption === "measurements"
              //     ? row?.nos *
              //       row?.length *
              //       row?.breadth *
              //       row?.height *
              //       row?.rate
              //     : row?.qty * row?.rate,
            };
          }),
        },
      });

      if (response?.data?.success) {
        // setshowSuccess(true);
        // setSuccessMessagePopup(response.data?.msg);
        navigate("/vendor-success");
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleAddRows() {
    if (selectedOption === "measurements") {
      setRows([
        ...rows,
        {
          site_code: "",
          item_id: "",
          item_name: "",
          nos: "",
          length: "",
          breadth: "",
          height: "",
          qty: "",
          unit: "",
          rate: "",
          amount: "",
        },
      ]);
    } else {
      setRows([
        ...rows,
        {
          site_code: "",
          item_id: "",
          item_name: "",
          qty: "",
          unit: "",
          rate: "",
          amount: "",
        },
      ]);
    }
  }

  async function getContractors(type) {
    try {
      // const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himapi/getfirmlist`,
        method: "GET",
        params: {
          type: type,
        },
        headers: {
          // jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      // if (error?.response?.status == 403) {
      //   localStorage.removeItem("him_user_token");
      //   localStorage.removeItem("him_user_data");
      //   navigate("/login");
      //   return;
      // }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }
  async function getItems() {
    try {
      // const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himapi/getitems`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      // if (error?.response?.status == 403) {
      //   localStorage.removeItem("him_user_token");
      //   localStorage.removeItem("him_user_data");
      //   navigate("/login");
      //   return;
      // }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      return [];
    }
  }

  // async function getRows() {
  //   try {
  //     // const token = localStorage.getItem("him_user_token");

  //     const response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendorquote`,
  //       method: "POST",
  //       // params: {
  //       //   ledger_master: "Office",
  //       // },
  //       data: {
  //         site_code: "",
  //       },
  //     });

  //     if (response.status === 200) {
  //       let data = response.data?.data;
  //       data = data.map((item) => {
  //         return {
  //           ...item,
  //           site_code: {
  //             value: item.site_code,
  //             label: item?.company?.code + "-" + item?.company?.name,
  //           },
  //         };
  //       });

  //       setRows(data);
  //     } else {
  //       setRows([]);
  //     }
  //   } catch (error) {
  //     // if (error?.response?.status == 403) {
  //     //   localStorage.removeItem("him_user_token");
  //     //   localStorage.removeItem("him_user_data");
  //     //   navigate("/login");
  //     //   return;
  //     // }

  //     if (error?.code === "ECONNABORTED") {
  //       console.log("Request timed out");
  //     } else {
  //       console.log("An error occurred:", error);
  //     }
  //   }
  // }

  useEffect(() => {
    (async () => {
      setSiteList(await getContractors("Firm"));
      let vendors = await getContractors("Contractor");
      let employees = await getContractors("Employee");
      // console.log(
      //   vendors?.find((item) => item?.code == fromBase64UrlSafe(code)),
      //   fromBase64UrlSafe(code)
      // );
      setVendor(vendors?.find((item) => item?.code == fromBase64UrlSafe(code)));
      setEmployee(
        employees?.find((item) => item?.code == fromBase64UrlSafe(emp))
      );
      setItemsList(await getItems());
    })();
    // getRows();
  }, []);

  return (
    <>
      <section className="DeskTopView">
        <div className="d-flex py-3 border-bottom">
          <div className="d-flex align-items-center mx-auto py-2">
            <div
              className="logBox me-3"
              style={{ width: "52px", height: "52px" }}
            >
              <img
                src={`${window.origin}/images/logo.png`}
                className="w-100"
                alt="logo"
              />
            </div>
            <h1 className="heading p-0 m-0">HIMCON</h1>
          </div>
        </div>

        <div className="container pt-4">
          <div className="row pb-3">
            <div className="col-12">
              <div className="d-flex flex-column text-center">
                <h1 className="heading mx-auto">
                  Contractor/Supplier Rate Quote By {vendor?.code}
                  {/* ({fromBase64UrlSafe(code || "")}) */}
                </h1>
                <h4 className="mx-auto">
                  Requested By {employee?.code}
                  {/* ({fromBase64UrlSafe(code || "")}) */}
                </h4>
              </div>
              <p className="text-center fs-4">Choose To Submit Rates In </p>

              <div className="d-flex mb-4">
                <select
                  className="input-box w-50 mx-auto"
                  aria-label="rateSelect"
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option selected value="measurements">
                    Rates In Measurements
                  </option>
                  <option value="quantity">Rates In Quantity</option>
                </select>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  onClick={handleAddRows}
                  disabled={isLoading}
                  type="button"
                  className="button button-brown me-4"
                >
                  + Add Row
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={isLoading || rows.length === 0}
                  type="button"
                  className="button button-brown"
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            {selectedOption === "measurements" && (
              <div className="col-12 d-flex">
                <div
                  style={{
                    minHeight: "400px",
                  }}
                  className="tbl-scroller"
                >
                  <table className="table-wrapper">
                    <thead>
                      <tr>
                        <th>Name Of Firm</th>
                        {/* <th>Item No.</th> */}
                        <th>Item Details</th>
                        <th>Numbers</th>
                        <th>Length</th>
                        <th>Breadth</th>
                        <th>Height</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Rate</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-gap">
                      <tr>
                        <td colSpan="13"></td>
                      </tr>
                    </tbody>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr>
                          <td>
                            <Select
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              value={row.site_code}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].site_code = e;
                                  return newRows;
                                });
                              }}
                              options={siteList.map((user) => ({
                                value: user?.code,
                                label: user?.code, //+ "-" + user?.name,
                              }))}
                              isSearchable
                              isClearable
                            />
                          </td>
                          <td>
                            {/* <input
                              value={row?.item_id}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_id = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            /> */}
                            <Select
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              value={row.item_id}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_id = e;
                                  newRows[index].item_name = e?.label;
                                  return newRows;
                                });
                              }}
                              options={itemsList.map((user) => ({
                                value: user?.item_id,
                                label: user?.item_name,
                              }))}
                              isSearchable
                              isClearable
                            />
                          </td>
                          {/* <td>
                            <input
                              value={row?.item_name}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_name = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            />
                          </td> */}
                          <td>
                            <input
                              value={row?.nos}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].nos = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].nos = parseTo2(e.target.value);
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.length}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].length = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].length = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.breadth}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].breadth = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].breadth = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.height}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].height = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].height = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              disabled
                              value={(
                                (!row?.nos ? 1 : parseFloat(row?.nos)) *
                                (!row?.length ? 1 : parseFloat(row?.length)) *
                                (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                                (!row?.height ? 1 : parseFloat(row?.height))
                              ).toFixed(2)}
                              // value={(
                              //   row?.nos *
                              //   row?.length *
                              //   row?.breadth *
                              //   row?.height
                              // ).toFixed(2)}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].qty = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].qty = parseTo2(e.target.value);
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.unit}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].unit = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.rate}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].rate = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].rate = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              disabled
                              value={(
                                (!row?.nos ? 1 : parseFloat(row?.nos)) *
                                (!row?.length ? 1 : parseFloat(row?.length)) *
                                (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                                (!row?.height ? 1 : parseFloat(row?.height)) *
                                row?.rate
                              ).toFixed(2)}
                              // value={(
                              //   row?.nos *
                              //   row?.length *
                              //   row?.breadth *
                              //   row?.height *
                              //   row?.rate
                              // ).toFixed(2)}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].amount = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].amount = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {selectedOption === "quantity" && (
              <div className="col-12 d-flex">
                <div
                  style={{
                    minHeight: "400px",
                  }}
                  className="tbl-scroller"
                >
                  <table className="table-wrapper">
                    <thead>
                      <tr>
                        <th>Name Of Firm</th>
                        {/* <th>Item No.</th> */}
                        <th>Item Details</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Rate</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-gap">
                      <tr>
                        <td colSpan="13"></td>
                      </tr>
                    </tbody>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr>
                          <td>
                            <Select
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              value={row.site_code}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].site_code = e;
                                  return newRows;
                                });
                              }}
                              options={siteList.map((user) => ({
                                value: user?.code,
                                label: user?.code, //+ "-" + user?.name,
                              }))}
                              isSearchable
                              isClearable
                            />
                          </td>
                          <td>
                            {/* <input
                              value={row?.item_id}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_id = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            /> */}
                            <Select
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              value={row.item_id}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_id = e;
                                  newRows[index].item_name = e?.label;
                                  return newRows;
                                });
                              }}
                              options={itemsList.map((user) => ({
                                value: user?.item_id,
                                label: user?.item_name,
                              }))}
                              isSearchable
                              isClearable
                            />
                          </td>
                          {/* <td>
                            <input
                              value={row?.item_name}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].item_name = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            />
                          </td> */}
                          <td>
                            <input
                              value={row?.qty}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].qty = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].qty = parseTo2(e.target.value);
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.unit}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].unit = e.target.value;
                                  return newRows;
                                });
                              }}
                              type="text"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              value={row?.rate}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].rate = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].rate = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                          <td>
                            <input
                              disabled
                              value={(row?.qty * row?.rate).toFixed(2)}
                              onChange={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].amount = e.target.value;
                                  return newRows;
                                });
                              }}
                              onBlur={(e) => {
                                setRows((prev) => {
                                  const newRows = [...prev];
                                  newRows[index].amount = parseTo2(
                                    e.target.value
                                  );
                                  return newRows;
                                });
                              }}
                              type="number"
                              className="input-box"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="MobileViewWrapper">
        <div className="MobileNav  mb-3">
          <div className="border-bottom py-2">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="logBox me-2">
                        <img
                          src={`${window.origin}/images/logo.png`}
                          className="w-100"
                          alt="logo"
                        />
                      </div>
                      {/* <h1 className="heading p-0 m-0">HIMCON</h1> */}
                    </div>
                    <div className="d-flex flex-column align-items-center ms-auto">
                      <h1 className="heading p-0 m-0 text-center">
                        Contractor/Supplier Rate Quote {vendor?.code}
                      </h1>
                      <h3 className=" p-0 m-0 text-center">
                        Requested By {employee?.code}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col">
                <div className="my-2">
                  <select
                    className="input-box "
                    aria-label="rateSelect"
                    onChange={handleSelectChange}
                    value={selectedOption}
                  >
                    <option selected value="measurements">
                      Rates In Measurements
                    </option>
                    <option value="quantity">Rates In Quantity</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {selectedOption == "measurements" && (
          <div className="container">
            <div className="row row-cols-1">
              <div
                style={{
                  marginTop: "90px",
                }}
                className="col"
              >
                {rows?.map((row, index) => (
                  <div className="MobileContent ">
                    <div className="mb-3">
                      <label htmlFor="ItemNumber" className="mb-10 d-block">
                        Name Of Firm
                      </label>
                      <Select
                        value={row.site_code}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].site_code = e;
                            return newRows;
                          });
                        }}
                        options={siteList.map((user) => ({
                          value: user?.code,
                          label: user?.code, //+ "-" + user?.name,
                        }))}
                        isSearchable
                        isClearable
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="ItemNumber" className="mb-10 d-block">
                        Item
                      </label>
                      <Select
                        value={row.item_id}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].item_id = e;
                            newRows[index].item_name = e?.label;
                            return newRows;
                          });
                        }}
                        options={itemsList.map((user) => ({
                          value: user?.item_id,
                          label: user?.item_name,
                        }))}
                        isSearchable
                        isClearable
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="ItemDetails" className="mb-10 d-block">
                        Item Details
                      </label>
                      <input
                        value={row?.item_name}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].item_name = e.target.value;
                            return newRows;
                          });
                        }}
                        type="text"
                        className="input-box"
                      />
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="nos" className="mb-10 d-block">
                        Numbers
                      </label>
                      <input
                        value={row?.nos}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].nos = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].nos = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="length" className="mb-10 d-block">
                        Length
                      </label>
                      <input
                        value={row?.length}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].length = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].length = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Breath" className="mb-10 d-block">
                        Breath
                      </label>
                      <input
                        value={row?.breadth}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].breadth = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].breadth = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Height" className="mb-10 d-block">
                        Height
                      </label>
                      <input
                        value={row?.height}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].height = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].height = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Quantity" className="mb-10 d-block">
                        Quantity
                      </label>
                      <input
                        disabled
                        value={(
                          (!row?.nos ? 1 : parseFloat(row?.nos)) *
                          (!row?.length ? 1 : parseFloat(row?.length)) *
                          (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                          (!row?.height ? 1 : parseFloat(row?.height))
                        ).toFixed(2)}
                        // value={(
                        //   row?.nos *
                        //   row?.length *
                        //   row?.breadth *
                        //   row?.height
                        // ).toFixed(2)}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].qty = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].qty = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Unit" className="mb-10 d-block">
                        Unit
                      </label>
                      <input
                        value={row?.unit}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].unit = e.target.value;
                            return newRows;
                          });
                        }}
                        type="text"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Rate" className="mb-10 d-block">
                        Rate
                      </label>
                      <input
                        value={row?.rate}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].rate = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].rate = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Amount" className="mb-10 d-block">
                        Amount
                      </label>
                      <input
                        disabled
                        value={(
                          (!row?.nos ? 1 : parseFloat(row?.nos)) *
                          (!row?.length ? 1 : parseFloat(row?.length)) *
                          (!row?.breadth ? 1 : parseFloat(row?.breadth)) *
                          (!row?.height ? 1 : parseFloat(row?.height)) *
                          row?.rate
                        ).toFixed(2)}
                        // value={(
                        //   row?.nos *
                        //   row?.length *
                        //   row?.breadth *
                        //   row?.height *
                        //   row?.rate
                        // ).toFixed(2)}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].amount = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].amount = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {selectedOption == "quantity" && (
          <div className="container">
            <div className="row row-cols-1">
              <div
                style={{
                  marginTop: "90px",
                }}
                className="col"
              >
                {rows?.map((row, index) => (
                  <div className="MobileContent">
                    <div className="mb-3">
                      <label htmlFor="ItemNumber" className="mb-10 d-block">
                        Name Of Firm
                      </label>
                      <Select
                        value={row.site_code}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].site_code = e;
                            return newRows;
                          });
                        }}
                        options={siteList.map((user) => ({
                          value: user?.code,
                          label: user?.code, //+ "-" + user?.name,
                        }))}
                        isSearchable
                        isClearable
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="ItemNumber" className="mb-10 d-block">
                        Item
                      </label>
                      <Select
                        value={row.item_id}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].item_id = e;
                            newRows[index].item_name = e?.label;
                            return newRows;
                          });
                        }}
                        options={itemsList.map((user) => ({
                          value: user?.item_id,
                          label: user?.item_name,
                        }))}
                        isSearchable
                        isClearable
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="ItemDetails" className="mb-10 d-block">
                        Item Details
                      </label>
                      <input
                        value={row?.item_name}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].item_name = e.target.value;
                            return newRows;
                          });
                        }}
                        type="text"
                        className="input-box"
                      />
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="Quantity" className="mb-10 d-block">
                        Quantity
                      </label>
                      <input
                        value={row?.qty}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].qty = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].qty = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Unit" className="mb-10 d-block">
                        Unit
                      </label>
                      <input
                        value={row?.unit}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].unit = e.target.value;
                            return newRows;
                          });
                        }}
                        type="text"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Rate" className="mb-10 d-block">
                        Rate
                      </label>
                      <input
                        value={row?.rate}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].rate = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].rate = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Amount" className="mb-10 d-block">
                        Amount
                      </label>
                      <input
                        disabled
                        value={(row?.qty * row?.rate).toFixed(2)}
                        onChange={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].amount = e.target.value;
                            return newRows;
                          });
                        }}
                        onBlur={(e) => {
                          setRows((prev) => {
                            const newRows = [...prev];
                            newRows[index].amount = parseTo2(e.target.value);
                            return newRows;
                          });
                        }}
                        type="number"
                        className="input-box"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="MobileFtr">
          <div className="container py-2">
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <button
                  onClick={handleAddRows}
                  disabled={isLoading}
                  type="button"
                  className="button button-default rounded-pill px-5"
                >
                  + Add New
                </button>

                <button
                  onClick={handleSubmit}
                  disabled={isLoading || rows.length === 0}
                  type="button"
                  className="button button-default rounded-pill ms-auto px-5"
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container">
        <div className="d-flex justify-content-between mt-4">
          <div className="">
            <button
              type="button"
              ref={ratesInMesureRef}
              className="button button-brown"
              onClick={handleSubmit}
            >
              Rate in Measurement
            </button>
          </div>
          <div className="">
            <button
              type="button"
              ref={ratesInQtyRef}
              className="button button-brown"
              onClick={handleSubmit}
            >
              Rate in Quantity
            </button>
          </div>
        </div>
      </div>
      {isLoading && <Loader />} */}
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default VendorCode;
